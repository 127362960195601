import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortByElement',
    pure: false
})
export class SortByElementPipe implements PipeTransform
{
    /**
     * Transform
     *
     * @param {any[]} value
     * @param {number} id
     * @param {string} property
     * @returns {any}
     */
    transform(value: any[], property: string): any
    {
        value.sort((a,b) => (a[property] < b[property]) ? 1 : ((b[property] < a[property]) ? -1 : 0));
        return value;
    }
}