import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {   id       : 'dashboards',
        title    : 'Dashboards',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id   : 'search',
                title: 'Search',
                type : 'item',
                url  : '/apps/dashboards/search/home',
                icon     : 'dashboard',
                
            },
            {
                id   : 'history',
                title: 'History',
                type : 'item',
                url  : '/apps/dashboards/search/history',
                icon     : 'search',
                
            },
        ]
    },
    {   id       : 'dnl',
        title    : 'Dnl New',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id   : 'dnl-shipment3',
                title: 'Shipment details list',
                type : 'item',
                url  : 'apps/dnl-new/dnl-shipment/shipment-list-details',
                icon     : 'dashboard',
            },
            {
                id   : 'dnl-shipment2',
                title: 'Shipment list',
                type : 'item',
                url  : 'apps/dnl-new/dnl-shipment/shipment-list',
                icon     : 'dashboard',
            },
            {
                id   : 'dnl-report2',
                title: 'Shipment report',
                type : 'item',
                url  : 'apps/dnl-new/dnl-shipment/shipment-report',
                icon     : 'dashboard',
            },
            {
                id   : 'dnl-cost-report2',
                title: 'Cost report',
                type : 'item',
                url  : 'apps/dnl-new/dnl-shipment/cost-report',
                icon     : 'dashboard',
            },
            {
                id   : 'dnl-revenue-report2',
                title: 'Revenue report',
                type : 'item',
                url  : 'apps/dnl-new/dnl-shipment/revenue-report',
                icon     : 'dashboard',
            },
            {
                id   : 'dnl-soa-report2',
                title: 'Soa report',
                type : 'item',
                url  : 'apps/dnl-new/dnl-shipment/soa2-report',
                icon     : 'dashboard',
            },
            {
                id   : 'soa_report',
                title: 'Soa agent',
                type : 'item',
                icon : 'send',
                url  : '/apps/dnl-new/dnl-shipment/soa-report',
            },
            {
                id       : 'dnl-report',
                title    : 'Report',
                type     : 'collapsable',
                icon     : 'dashboard',
                children : [
                    {
                        id   : 'connect',
                        title: 'Connect',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/dnl-new/dnl-report/connect',
                    },
                    {
                        id   : 'soa_report2',
                        title: 'SOA report',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/dnl-new/dnl-report/soa/select',
                    },
                    {
                        id   : 'bank_report',
                        title: 'Bank connect report',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/dnl-new/dnl-report/bank-connect',
                    },
                ]
            },
            {
                id       : 'dnl-change',
                title    : 'Change',
                type     : 'collapsable',
                icon     : 'dashboard',
                children : [
                    {
                        id   : 'bank-list',
                        title: 'Bank list',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/dnl-new/dnl-bank/bank-list',
                    },
                    {
                        id   : 'invoice-list',
                        title: 'Invoice',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/dnl-new/dnl-invoice/invoice-list',
                    },
                    {
                        id   : 'invoice-import',
                        title: 'Invoice import',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/dnl-new/dnl-invoice/invoice-import',
                    },
                    {
                        id   : 'shipment-list',
                        title: 'Shipment list',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/dnl-new/dnl-change/shipment-list',
                    },
                    {
                        id   : 'revenue-list',
                        title: 'Revenue list',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/dnl-new/dnl-change/revenue-list',
                    },
                    {
                        id   : 'cost-list',
                        title: 'Cost list',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/dnl-new/dnl-change/cost-list',
                    },
                    {
                        id   : 'correct-list',
                        title: 'Correct list',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/dnl-new/dnl-change/correct-list',
                    },
                    {
                        id   : 'otherin-list',
                        title: 'Other in list',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/dnl-new/dnl-change/otherin-list',
                    },
                    {
                        id   : 'otherout-list',
                        title: 'Other out list',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/dnl-new/dnl-change/otherout-list',
                    },
                    {
                        id   : 'party-list',
                        title: 'Party list',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/dnl-new/dnl-change/party-list',
                    },
                    {
                        id   : 'quote-list',
                        title: 'Quote list',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/dnl-new/dnl-change/quote-list',
                    },
                    {
                        id   : 'quotedetails-list',
                        title: 'Quote details list',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/dnl-new/dnl-change/quotedetails-list',
                    },
                    {
                        id   : 'quotecost-list',
                        title: 'Quote cost list',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/dnl-new/dnl-change/quotecost-list',
                    },
                    {
                        id   : 'invoice-check',
                        title: 'Invoice check',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/dnl-new/dnl-change/invoice-check',
                    },
                ]
            }
        ]
    },
    {   id       : 'sel',
        title    : 'Sel',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id   : 'sel-book',
                title: 'Booking',
                type : 'item',
                url  : 'apps/sel/sel-book/get-book',
                icon     : 'dashboard',
            },
            {
                id   : 'sel-book2',
                title: 'Booking with Price',
                type : 'item',
                url  : 'apps/sel/sel-book/get-book2',
                icon     : 'dashboard',
            },
            {
                id   : 'sel-book-list',
                title: 'Booking list',
                type : 'item',
                url  : '/apps/sel/sel-book/book-list',
                icon     : 'dashboard',
                
            },
            {
                id   : 'sel-book-space',
                title: 'Booking space',
                type : 'item',
                url  : '/apps/sel/sel-book/book-space',
                icon     : 'dashboard',
                
            },
            {
                id   : 'sel-shipment-list',
                title: 'Shipment list',
                type : 'item',
                url  : 'apps/sel/sel-book/book-list2',
                icon     : 'dashboard',
                
            },
            {
                id   : 'sel-shipment-list2',
                title: 'Shipment list2',
                type : 'item',
                url  : 'apps/sel/sel-book/shipment-list2',
                icon     : 'dashboard',
                
            },
            {
                id   : 'sel-revenue-inv',
                title: 'Export invoice',
                type : 'item',
                url  : 'apps/sel/sel-invoice/revenue-invoice',
                icon     : 'dashboard',
            },
            {
                id       : 'sel_invoice_revenue',
                title    : 'Sel invoice revenue',
                type     : 'item',
                icon     : 'note',
                url  : 'apps/sel/sel-book/shipment-revenue'
            },
            {
                id       : 'sel_invoice_cost',
                title    : 'Sel invoice cost',
                type     : 'item',
                icon     : 'note',
                url  : 'apps/sel/sel-book/shipment-cost'
            },
            {
                id       : 'sel_invoice_cost',
                title    : 'Sel invoice cost(vessel)',
                type     : 'item',
                icon     : 'note',
                url  : 'apps/sel/sel-book/shipment-cost1'
            },
            {
                id       : 'sel_invoice_cost3',
                title    : 'Sel invoice cost(vessel) 2',
                type     : 'item',
                icon     : 'note',
                url  : 'apps/sel/sel-book/shipment-cost3'
            },
            {
                id       : 'sel_invoice_cost2',
                title    : 'Sel invoice cost(time)',
                type     : 'item',
                icon     : 'note',
                url  : 'apps/sel/sel-book/shipment-cost2'
            },
            
            {
                id       : 'report-shipment',
                title    : 'Report',
                type     : 'collapsable',
                icon     : 'dashboard',
                children : [
                    {
                        id   : 'sel-shipment-report',
                        title: 'Shipment report',
                        type : 'item',
                        url  : 'apps/sel/sel-report/shipment-report',
                        icon     : 'dashboard',
                    },
                    {
                        id   : 'sel-shipment-report2',
                        title: 'Shipment currency report',
                        type : 'item',
                        url  : 'apps/sel/sel-report/shipment-report2',
                        icon     : 'dashboard',
                    },
                    {
                        id   : 'sel-revenue-report3',
                        title: 'Shipment revenue',
                        type : 'item',
                        url  : 'apps/sel/sel-report/revenue-report3',
                        icon     : 'dashboard',
                    },
                    {
                        id   : 'sel-cost-report3',
                        title: 'Shipment cost',
                        type : 'item',
                        url  : 'apps/sel/sel-report/cost-report3',
                        icon     : 'dashboard',
                    },
                    {
                        id   : 'sel-revenue-report',
                        title: 'Revenue report',
                        type : 'item',
                        url  : 'apps/sel/sel-report/revenue-report',
                        icon     : 'dashboard',
                    },

                    {
                        id   : 'sel-cost-report',
                        title: 'Cost report',
                        type : 'item',
                        url  : 'apps/sel/sel-report/cost-report',
                        icon     : 'dashboard',
                    },
                    {
                        id   : 'sel-cost-report2',
                        title: 'Cost report 2',
                        type : 'item',
                        url  : 'apps/sel/sel-report/cost-report2',
                        icon     : 'dashboard',
                    },
                    {
                        id   : 'sel-deposit-report',
                        title: 'Deposit report',
                        type : 'item',
                        url  : 'apps/sel/sel-report/shipment-deposit',
                        icon     : 'dashboard',
                    },
                ]
            },
        ]
    },
    {   id       : 'dnl',
        title    : 'Dnl',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id   : 'dnl-shipment1',
                title: 'Shipment list details',
                type : 'item',
                url  : '/apps/dnl/dnl-shipment/shipment-list-details',
                icon     : 'dashboard',
            },
            {
                id   : 'dnl-shipment',
                title: 'Shipment list',
                type : 'item',
                url  : 'apps/dnl/dnl-shipment/shipment-list',
                icon     : 'dashboard',
            },/*
            {
                id   : 'dnl-shipment-report',
                title: 'Shipment Report',
                type : 'item',
                url  : '/apps/dnl/dnl-report/shipment-report2',
                icon     : 'dashboard',
            },
            {
                id   : 'dnl-revenue-connect',
                title: 'Revenue Connect Report',
                type : 'item',
                url  : '/apps/dnl/dnl-report/revenue-connect',
                icon     : 'dashboard',
            },
            {
                id   : 'dnl-cost-connect',
                title: 'Cost Connect Report',
                type : 'item',
                url  : '/apps/dnl/dnl-report/cost-connect',
                icon     : 'dashboard',
            },
            {
                id   : 'dnl-bank-out-connect',
                title: 'Bank Out Connect Report',
                type : 'item',
                url  : '/apps/dnl/dnl-report/bank-out-connect',
                icon     : 'dashboard',
            },
            {
                id   : 'dnl-soa-multi',
                title: 'SOA Report 2',
                type : 'item',
                url  : '/apps/dnl/dnl-report/soa',
                icon     : 'dashboard',
            },
            {
                id   : 'dnl-soa-multi',
                title: 'SOA Report',
                type : 'item',
                url  : '/apps/dnl/dnl-report/soa2',
                icon     : 'dashboard',
            },
            {
                id   : 'dnl-cost-report',
                title: 'Cost Report',
                type : 'item',
                url  : '/apps/dnl/dnl-report/cost-report2',
                icon     : 'dashboard',
            },*/
        ]
    },
    {   id       : 'cms',
        title    : 'CMS',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id       : 'customer',
                title    : 'Customer',
                type     : 'item',
                icon     : 'contacts',
                url  : '/apps/cms/customer/list'
            },
            {
                id       : 'customer-relation',
                title    : 'Customer relation',
                type     : 'item',
                icon     : 'contacts',
                url  : '/apps/cms/customer/relation/list'
            },
            {
                id       : 'customer-report',
                title    : 'Customer report',
                type     : 'item',
                icon     : 'contacts',
                url  : '/apps/cms/customer-report'
            },
            {
                id   : 'sel-quote',
                title: 'Quote',
                type : 'item',
                url  : 'apps/cms/quote/list',
                icon     : 'dashboard',
            },
        ]
    },
    {   id       : 'cms2',
        title    : 'DNL CMS',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id       : 'partner',
                title    : 'Partner',
                type     : 'item',
                icon     : 'contacts',
                url  : '/apps/dnl-cms/dnl-partner/list'
            },
            {
                id       : 'partner-relation',
                title    : 'Partner relation',
                type     : 'item',
                icon     : 'contacts',
                url  : '/apps/dnl-cms/dnl-partner/relation'
            },
            {
                id       : 'partner-report2',
                title    : 'Partner report',
                type     : 'item',
                icon     : 'contacts',
                url  : '/apps/dnl-cms/dnl-partner-report'
            },
            {
                id   : 'dnl-quote',
                title: 'Dnl Quote',
                type : 'item',
                url  : 'apps/dnl-cms/dnl-quote/list',
                icon     : 'dashboard',
            },
        ]
    },
    {   id       : 'accounting',
        title    : 'Accounting',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id   : 'acc-revenue',
                title: 'Revenue',
                type : 'item',
                url  : 'apps/acc/sel-rev-ledger/list',
                icon     : 'dashboard',
            },
            {
                id   : 'acc-revenue1',
                title: 'Report revenue',
                type : 'item',
                url  : 'apps/acc/sel-rev-ledger/report/',
                icon     : 'dashboard',
            },
            {
                id   : 'acc-revenue2',
                title: 'Import invoice revenue',
                type : 'item',
                url  : 'apps/acc/sel-rev-ledger/import',
                icon     : 'dashboard',
            },
            {
                id   : 'rev-connect',
                title: 'Revenue connect',
                type : 'item',
                url  : 'apps/acc/sel-rev-ledger/connect',
                icon     : 'dashboard',
            },
            {
                id   : 'acc-cost',
                title: 'Cost',
                type : 'item',
                url  : 'apps/acc/sel-cost-ledger/list',
                icon     : 'dashboard',
            },
            {
                id   : 'acc-cost1',
                title: 'Report cost',
                type : 'item',
                url  : 'apps/acc/sel-cost-ledger/report/',
                icon     : 'dashboard',
            },
            {
                id   : 'acc-cost2',
                title: 'Import invoice cost',
                type : 'item',
                url  : 'apps/acc/sel-cost-ledger/import',
                icon     : 'dashboard',
            },
            {
                id   : 'cost-connect',
                title: 'Cost connect',
                type : 'item',
                url  : 'apps/acc/sel-cost-ledger/connect',
                icon     : 'dashboard',
            },
            
            {
                id   : 'bank',
                title    : 'Bank',
                type     : 'collapsable',
                icon     : 'attach_money',
                children : [
                    {
                        id   : 'bank-list',
                        title: 'Bank list',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/acc/bank/list',
                    },
                    {
                        id   : 'company-list',
                        title: 'Company list',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/acc/bank/company-list',
                    },
                    {
                        id   : 'bank-account',
                        title: 'Bank account',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/acc/bank/account-list',
                    },
                    {
                        id   : 'bank-in',
                        title: 'Bank in',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/acc/bank/bank-in',
                    },
                    {
                        id   : 'bank-out',
                        title: 'Bank out',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/acc/bank/bank-out',
                    },
                    {
                        id   : 'bank-rev',
                        title: 'Bank revenue connect',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/acc/bank/bank-rev-connect',
                    },
                    {
                        id   : 'bank-cost',
                        title: 'Bank cost connect',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/acc/bank/bank-cost-connect',
                    },
                    {
                        id   : 'bank',
                        title: 'Bank import',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/acc/bank/bank-import/SEL',
                    },
                ]
            },
        ]
    },
    {   id       : 'dnl-accounting',
        title    : 'Dnl Accounting',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id   : 'dnl-acc-revenue1',
                title: 'Revenue Invoice List',
                type : 'item',
                url  : 'apps/acc/dnl-rev-ledger/list',
                icon     : 'dashboard',
            },
            {
                id   : 'dnl-acc-revenue2',
                title: 'Revenue Import',
                type : 'item',
                url  : 'apps/acc/dnl-rev-ledger/import/0',
                icon     : 'dashboard',
            },
            {
                id   : 'dnl-acc-revenue3',
                title: 'Revenue Connect',
                type : 'item',
                url  : 'apps/acc/dnl-rev-ledger/connect',
                icon     : 'dashboard',
            },
            {
                id   : 'dnl-acc-cost',
                title: 'Cost Invoice List',
                type : 'item',
                url  : 'apps/acc/dnl-cost-ledger/list',
                icon     : 'dashboard',
            },
            {
                id   : 'dnl-acc-cost2',
                title: 'Cost Import',
                type : 'item',
                url  : 'apps/acc/dnl-cost-ledger/import/0',
                icon     : 'dashboard',
            },
            {
                id   : 'dnl-acc-cost3',
                title: 'Cost Connect',
                type : 'item',
                url  : 'apps/acc/dnl-cost-ledger/connect',
                icon     : 'dashboard',
            },
            {
                id   : 'bank',
                title    : 'Bank',
                type     : 'collapsable',
                icon     : 'attach_money',
                children : [
                    {
                        id   : 'bank',
                        title: 'Bank',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/acc/bank/list',
                    },
                    {
                        id   : 'company-list',
                        title: 'Company list',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/acc/bank/company-list',
                    },
                    {
                        id   : 'bank-account',
                        title: 'Bank account',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/acc/bank/account-list',
                    },
                    {
                        id   : 'bank-in',
                        title: 'Bank in',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/acc/bank/dnl-bank-in',
                    },
                    {
                        id   : 'bank-out',
                        title: 'Bank out',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/acc/bank/dnl-bank-out',
                    },
                    {
                        id   : 'bank',
                        title: 'Bank import',
                        type : 'item',
                        icon : 'send',
                        url  : '/apps/acc/bank/bank-import/DNL',
                    },
                ]
            },
        ]
    },
    {
        id       : 'Admin',
        title    : 'Admin',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id   : 'home',
                title: 'Home',
                type : 'item',
                url  : '/apps/admin/home',
                icon     : 'dashboard',
                
            },
            {
                id       : 'admin',
                title    : 'Admin',
                type     : 'item',
                icon     : 'group',
                url      : '/apps/admin/list'
            },
            {
                id       : 'time-line',
                title    : 'User time line',
                type     : 'item',
                icon     : 'timeline',
                url      : '/apps/admin/time-line/list'
            },
        ],
    },
    {
        id       : 'Container',
        title    : 'Container',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id   : 'dashboard1',
                title: 'Dashboard',
                type : 'item',
                icon     : 'dashboard',
                url  : '/apps/container/dashboard',
            },
            {
                id   : 'cont',
                title: 'Container list',
                type : 'item',
                url  : '/apps/container/list',
                icon     : 'dashboard',
                
            },
            {
                id   : 'cont2',
                title: 'Container code list',
                type : 'item',
                url  : '/apps/container/code-list',
                icon     : 'dashboard',
                
            },
            {
                id       : 'cont3',
                title    : 'Container condition',
                type     : 'item',
                icon     : 'group',
                url      : '/apps/container/condition'
            },
            {
                id   : 'cont4',
                title: 'Container import',
                type : 'item',
                url  : '/apps/container/multi-container',
                icon     : 'dashboard',
                 
            },
        ]
    },
    {
        id       : 'movement',
        title    : 'Movement',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id       : 'containerList2',
                title    : 'Container list',
                type     : 'item',
                icon     : 'group',
                url      : '/apps/movement/container/list/'
            },
            {
                id       : 'movementList',
                title    : 'Movement list',
                type     : 'item',
                icon     : 'group',
                url      : '/apps/movement/list/0'
            },
            {
                id       : 'movementList2',
                title    : 'Movement filter',
                type     : 'item',
                icon     : 'group',
                url      : '/apps/movement/list2'
            },
            {
                id       : 'movementImport',
                title    : 'Movement import',
                type     : 'item',
                icon     : 'group',
                url      : '/apps/movement/multi-movement'
            },
            {
                id   : 'movementDelete',
                title: 'Movement delete',
                type : 'item',
                icon : 'group',
                url  : '/apps/movement/multi-del',
            },
            {
                id   : 'movementShipment',
                title: 'Movement shipment',
                type : 'item',
                url  : 'apps/movement/shipment',
                icon     : 'dashboard',
                
            },
            {
                id       : 'cont2',
                title    : 'Report',
                type     : 'collapsable',
                icon     : 'dashboard',
                children : [
                    {
                        id   : 'move-report1',
                        title: 'Movement report',
                        type : 'item',
                        url  : '/apps/movement/report/movement',
                    },
                    {
                        id   : 'move-report2',
                        title: 'Empty Storage report',
                        type : 'item',
                        url  : '/apps/movement/report/move-empty',
                    },
                    {
                        id   : 'move-report3',
                        title: 'Laden Storage (import) report',
                        type : 'item',
                        url  : '/apps/movement/report/move-fulim',
                    },
                    {
                        id   : 'move-report4',
                        title: 'Laden Storage (export) report',
                        type : 'item',
                        url  : '/apps/movement/report/move-fulex',
                    },
                    {
                        id   : 'move-report5',
                        title: 'Detention (import) report',
                        type : 'item',
                        url  : '/apps/movement/report/move-trimp',
                    },
                    {
                        id   : 'move-report6',
                        title: 'Detention (export) report',
                        type : 'item',
                        url  : '/apps/movement/report/move-trexp',
                    },
                ]
            },
        ]
    },
    {
        id       : 'Lease',
        title    : 'Lease',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id       : 'lease',
                title    : 'Lease',
                type     : 'item',
                icon     : 'group',
                url      : '/apps/lease/list'
            },
            {
                id       : 'lease2',
                title    : 'Report',
                type     : 'collapsable',
                icon     : 'dashboard',
                children : [
                    {
                        id   : 'dashboard2',
                        title: 'Lease Invoice',
                        type : 'item',
                        url  : '/apps/lease/lease-invoice',
                    },
                ]
            },
        ]
    },
    {
        id       : 'vessel-load',
        title    : 'Vessel loading',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id       : 'load-import',
                title    : 'Load import',
                type     : 'item',
                icon     : 'group',
                url      : '/apps/vessel-load/voyage/list/load',
            },
            {
                id       : 'fulim-import',
                title    : 'Fulim import',
                type     : 'item',
                icon     : 'group',
                url      : '/apps/vessel-load/voyage/list/fulim',
            },
            /*
            {
                id       : 'load-import',
                title    : 'Load import',
                type     : 'item',
                icon     : 'group',
                url      : '/apps/vessel-load/voyage/import'
            },*/
        ]
    },
    {
        id       : 'humanResources',
        title    : 'Human Resources',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id   : 'time_shift',
                title: 'Time Shift',
                type : 'item',
                url  : '/apps/human-resources/timeshift',
                icon     : 'dashboard',
                
            },
            {
                id   : 'profit_report',
                title: 'Profit report',
                type : 'item',
                url  : '/apps/human-resources/profit-report',
                icon     : 'dashboard',
                
            },
        ],
    },
    {
        id       : 'humanResourcesAdmin',
        title    : 'Human Resources Admin',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id   : 'holiday',
                title: 'Holiday',
                type : 'item',
                url  : '/apps/human-resources-admin/holiday/list',
                icon     : 'dashboard',
                
            },
            {
                id   : 'policy',
                title: 'Policy',
                type : 'item',
                url  : '/apps/human-resources-admin/policy/list',
                icon     : 'dashboard',
                
            },
            {
                id   : 'import_sheet',
                title: 'Timesheet import',
                type : 'item',
                url  : '/apps/human-resources-admin/import',
                icon     : 'dashboard',
                
            },
            {
                id   : 'list_sheet',
                title: 'Timesheet list',
                type : 'item',
                url  : '/apps/human-resources-admin/timelist/list',
                icon     : 'dashboard',
                
            },
        ],
    },
    {
        id       : 'statistics',
        title    : 'Statistics',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id       : 'users',
                title    : 'Users',
                type     : 'item',
                icon     : 'group',
                url  : '/apps/statistics/user/list'
            },
            {
                id       : 'port-pair',
                title    : 'Port pairs',
                type     : 'item',
                icon     : 'keyboard_tab',
                url  : '/apps/statistics/port-pair-list/list'
            },
            {
                id       : 'fcl',
                title    : 'FCL 2',
                type     : 'item',
                icon     : 'list_alt',
                url  : '/apps/statistics/fclcheck/list2'
            },
        ]
    },
    {
        id       : 'places',
        title    : 'Places',
        type     : 'group',
        icon     : 'place',
        children : [
            {
                id       : 'country',
                title    : 'Country',
                type     : 'item',
                icon     : 'business',
                url  : '/apps/place/country/list'
            },
            {
                id       : 'seaport',
                title    : 'Seaport',
                type     : 'item',
                icon     : 'place',
                url  : '/apps/place/seaport/list'
            },
            {
                id       : 'sea-terminal',
                title    : 'Sea terminal',
                type     : 'item',
                icon     : 'place',
                url  : '/apps/place/sea-terminal/list'
            },
            {
                id       : 'sea-cfs',
                title    : 'Sea CFS',
                type     : 'item',
                icon     : 'place',
                url  : '/apps/place/sea-cfs/list'
            },
            {
                id       : 'airport',
                title    : 'Airport',
                type     : 'item',
                icon     : 'place',
                url  : '/apps/place/airport/list'
            },
            {
                id       : 'air-terminal',
                title    : 'Air terminal',
                type     : 'item',
                icon     : 'place',
                url  : '/apps/place/air-terminal/list'
            },
            {
                id       : 'depot',
                title    : 'Depot',
                type     : 'item',
                icon     : 'place',
                url  : '/apps/place/depot/list'
            },
            {
                id       : 'truck-point',
                title    : 'Truck point',
                type     : 'item',
                icon     : 'place',
                url  : '/apps/place/truck-point/list'
            },
        ]
    },
    {
        id       : 'partner',
        title    : 'Partner Control',
        type     : 'group',
        icon     : 'local_shipping',
        children : [
            {
                id       : 'partner2',
                title    : 'Partner',
                type     : 'item',
                icon     : 'local_shipping',
                url  : '/apps/partner/partner/list'
            },
            {
                id       : 'partner3',
                title    : 'Partner check',
                type     : 'item',
                icon     : 'local_shipping',
                url  : '/apps/partner/partner/check'
            },
        ]
    },
    {
        id       : 'supplier',
        title    : 'Supplier Control',
        type     : 'group',
        icon     : 'local_shipping',
        children : [
            {
                id       : 'supplier2',
                title    : 'Supplier',
                type     : 'item',
                icon     : 'local_shipping',
                url  : '/apps/supplier/supplier/list'
            },
            {
                id       : 'supplier3',
                title    : 'Supplier Report',
                type     : 'item',
                icon     : 'local_shipping',
                url  : '/apps/supplier/supplier-report'
            },
            {
                id       : 'sel-vendor',
                title    : 'Sel vendor',
                type     : 'item',
                icon     : 'note',
                url  : '/apps/supplier/sel-vendor/list'
            },
            {
                id       : 'sel-contract',
                title    : 'Sel contract',
                type     : 'item',
                icon     : 'note',
                url  : '/apps/supplier/sel-contract/list'
            },
        ]
    },
    {
        id       : 'setting',
        title    : 'Setting Control',
        type     : 'group',
        icon     : 'settings',
        children : [
            {
                id       : 'vessel',
                title    : 'Vessel',
                type     : 'item',
                icon     : 'directions_boat',
                url  : '/apps/setting/vessel/list'
            },
            {
                id       : 'voyage',
                title    : 'Voyage',
                type     : 'item',
                icon     : 'directions_boat',
                url  : '/apps/setting/voyage/list'
            },
            {
                id       : 'currency',
                title    : 'Currency',
                type     : 'item',
                icon     : 'settings',
                url  : '/apps/setting/currency/list'
            },
            {
                id       : 'price',
                title    : 'Price',
                type     : 'item',
                icon     : 'settings',
                url  : '/apps/setting/price/list'
            },
            {
                id       : 'commodity',
                title    : 'Commodity',
                type     : 'item',
                icon     : 'settings',
                url  : '/apps/setting/commodity/list'
            },
            {
                id       : 'package',
                title    : 'Unit of price',
                type     : 'item',
                icon     : 'settings',
                url  : '/apps/setting/package/list'
            },
            {
                id       : 'packageType',
                title    : 'Package type',
                type     : 'item',
                icon     : 'settings',
                url  : '/apps/setting/package-type/list'
            },
            {
                id       : 'moveType',
                title    : 'Move type',
                type     : 'item',
                icon     : 'group',
                url      : '/apps/setting/move-type/list'
            },
            {
                id       : 'voyage-import',
                title    : 'Voyage import',
                type     : 'item',
                icon     : 'directions_boat',
                url  : '/apps/import/form'
            },/*
            {
                id       : 'tarrif',
                title    : 'Fcl Tarrif',
                type     : 'item',
                icon     : 'settings',
                url  : '/apps/setting/tarrif/list'
            },*/
            {
                id   : 'sel-tarrif',
                title: 'Sel Tarrif',
                type : 'item',
                url  : 'apps/setting/sel-tarrif/list',
                icon     : 'dashboard',
            },
            {
                id   : 'relation-type',
                title: 'Relation type',
                type : 'item',
                url  : 'apps/setting/relation-type/list',
                icon     : 'dashboard',
            },
            {
                id   : 'scope',
                title: 'Scope',
                type : 'item',
                url  : 'apps/setting/scope/list',
                icon     : 'dashboard',
            },
            {
                id   : 'relation',
                title: 'Relation',
                type : 'item',
                url  : 'apps/setting/relation/list',
                icon     : 'dashboard',
            },
            {
                id       : 'workflow',
                title    : 'Workflow',
                type     : 'item',
                icon     : 'settings',
                url  : '/apps/setting/workflow/list'
            },
            {
                id       : 'flowstep',
                title    : 'Flowstep',
                type     : 'item',
                icon     : 'settings',
                url  : '/apps/setting/flowstep/list'
            },
            {
                id       : 'ipoint',
                title    : 'Ipoint',
                type     : 'item',
                icon     : 'settings',
                url  : '/apps/setting/ipoint/list'
            },
            {
                id       : 'priceamis',
                title    : 'Price amis',
                type     : 'item',
                icon     : 'settings',
                url  : '/apps/setting/priceamis/list'
            },
            {
                id       : 'partneramis',
                title    : 'Partner amis',
                type     : 'item',
                icon     : 'settings',
                url  : '/apps/setting/partneramis/list'
            },
        ]
    },
    {
        id       : 'service',
        title    : 'Service',
        type     : 'group',
        icon     : 'list_alt',
        children : [
            {
                id       : 'fcl2',
                title    : 'FCL 2',
                type     : 'item',
                icon     : 'list_alt',
                url  : '/apps/service/fcl/list2/1'
            },
        ]
    },
];
