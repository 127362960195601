import { NgModule } from '@angular/core';

import { KeysPipe } from './keys.pipe';
import { GetByIdPipe } from './getById.pipe';
import { GetByColPipe } from './getByCol.pipe';

import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { FilterPipe } from './filter.pipe';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { SortByElementPipe } from './sortByElement.pipe';
@NgModule({
    declarations: [
        KeysPipe,
        GetByIdPipe,
        GetByColPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        SortByElementPipe
    ],
    imports     : [],
    exports     : [
        KeysPipe,
        GetByIdPipe,
        GetByColPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        SortByElementPipe
    ]
})
export class FusePipesModule
{
}
