import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders} from '@angular/common/http';

import { DatePipe } from '@angular/common';

import { tap, delay } from 'rxjs/operators';
declare const gapi : any;

@Injectable()
export class AuthService {
  public isLoggedIn = false;
  public version;
  public user;
  public userType;
  public email;
  public name;
  public permission;
  public modul;
  public company;
  public branch;
  public department;
  public companyMail;
  public accessToken;
  public checkValue = '';
  public back = false;
  public tags = [
        {'id': 1, 'tag': '20DC', 'color' : '#388E3C'},
        {'id': 2, 'tag': '40HC', 'color' : '#F44336'},
        {'id': 3, 'tag': 'Import', 'color' :'#FF9800'},
        {'id': 4, 'tag': 'Export', 'color' : '#0091EA'},
        {'id': 5, 'tag': 'Asia', 'color' : '#9C27B0'},
        {'id': 6, 'tag': 'Europe', 'color' : '#9C27B0'},
        {'id': 7, 'tag': 'America', 'color' : '#9C27B0'},
        {'id': 8, 'tag': 'Domestics', 'color' : '#9C27B0'},
        {'id': 9, 'tag': 'SEL', 'color' : '#DE147B'}
    ];
  public companyInfo =  [
      {
          company: 0,
          info: {
              logo: "assets/images/logos/IshiptoLogos.jpg",
              title: "ISHIPTO",
              address: "R506, F5th V.E.T Building, 98 Hoang Quoc Viet Street, Cau Giay Dist, Hanoi, Vietnam",
              website: "www.ishipto.vn",
              head_office: {
                  address: "Rm 506, 05 Fl., V.E.T Building, 98 Hoang Quoc Viet Str., Cau Giay Dist., Hanoi city, Vietnam",
                  tel: "(84-024) 3 212 1246/ 47",
              },
              hochiminh: {
                  address: "Rm 601, 31 Hoang Dieu Street, Dist 4, Hochiminh City, Vietnam",
                  tel: "(84-028) 3 943 5512",
              },
              haiphong: {
                  address: "Rm 208, Holiday Mansion Hotel, 441 Da Nang Str., Hai An Dist., Hai Phong city, Vietnam",
                  tel: "(84-0225) 3 555 344"
              },
              print: [
                  {
                      place: 0,
                      name: "HAIPHONG",
                      sales: { 
                          name: "Mrs Cung Lan",
                          ext: "23",
                          mob: "0986929882",
                          email: "sales@sel.vn"
                      },
                      
                  },
                  {
                      place: 1,
                      name: "HOCHIMINH",
                      address : "Rm 601, 31 Hoang Dieu Str, Dist 4, Hochiminh City, Vietnam",
                      note: "For information about shipping, booking, telex release, cargo tracking, … do not hesitate contact us.",
                      sales: { 
                          name: "Mr Khanh",
                          ext: "23",
                          tel: "08 3 9435512 / 3 9435513",
                          fax: "08 3 9435514",
                      },
                      
                  },
              ],
          },
      },
      {
          company: 1,
          info: {
              logo: "assets/images/logos/SEFullLogos.jpg",
              title: "SE GLOBAL LINES JSC",
              address: "R506B, F5th V.E.T Building, 98 Hoang Quoc Viet Street, Cau Giay Dist, Hanoi, Vietnam",
              website: "www.sel.vn",
              head_office: {
                  address: "Rm 506, 05 Fl., V.E.T Building, 98 Hoang Quoc Viet Str., Cau Giay Dist., Hanoi city, Vietnam",
                  tel: "(84-024) 3 212 1246/ 47",
              },
              hochiminh: {
                  address: "Rm 601, 31 Hoang Dieu Street, Dist 4, Hochiminh City, Vietnam",
                  tel: "(84-028) 3 943 5512",
              },
              haiphong: {
                  address: "Rm 208, Holiday Mansion Hotel, 441 Da Nang Str., Hai An Dist., Hai Phong city, Vietnam",
                  tel: "(84-0225) 3 555 344"
              },
              print: [
                  {
                      place: 0,
                      name: "HAIPHONG",
                      sales: { 
                          name: "Mrs Cung Lan",
                          ext: "23",
                          mob: "0986929882",
                          email: "sales@sel.vn"
                      },
                      
                  },
                  {
                      place: 1,
                      name: "HOCHIMINH",
                      address : "Rm 601, 31 Hoang Dieu Str, Dist 4, Hochiminh City, Vietnam",
                      note: "For information about shipping, booking, telex release, cargo tracking, … do not hesitate contact us.",
                      sales: { 
                          name: "Mr Khanh",
                          ext: "23",
                          tel: "08 3 9435512 / 3 9435513",
                          fax: "08 3 9435514",
                      },
                      
                  },
              ],
          },
      },
      {
          company: 2,
          info: {
              logo: "assets/images/logos/DongnamLogos.jpg",
              title: "DONG NAM LOGISTICS COMPANY LIMITED",
              address: "R506, F5th V.E.T Building, 98 Hoang Quoc Viet Street, Cau Giay Dist, Hanoi, Vietnam",
              website: "www.dnlogis.vn",
              head_office: {
                  address: "Rm 506, 05 Fl., V.E.T Building, 98 Hoang Quoc Viet Str., Cau Giay Dist., Hanoi city, Vietnam",
                  tel: "(84-024) 3 212 1246/ 47",
              },
              hochiminh: {
                  address: "Rm 601, 31 Hoang Dieu Street, Dist 4, Hochiminh City, Vietnam",
                  tel: "(84-028) 3 943 5512",
              },
              haiphong: {
                  address: "Rm 208, Holiday Mansion Hotel, 441 Da Nang Str., Hai An Dist., Hai Phong city, Vietnam",
                  tel: "(84-0225) 3 555 344"
              },
              print: [
                  {
                      place: 0,
                      name: "HAIPHONG",
                      sales: { 
                          name: "Mrs Cung Lan",
                          ext: "23",
                          mob: "0986929882",
                          email: "sales@sel.vn"
                      },
                      
                  },
                  {
                      place: 1,
                      name: "HOCHIMINH",
                      address : "Rm 601, 31 Hoang Dieu Str, Dist 4, Hochiminh City, Vietnam",
                      note: "For information about shipping, booking, telex release, cargo tracking, … do not hesitate contact us.",
                      sales: { 
                          name: "Mr Khanh",
                          ext: "23",
                          tel: "08 3 9435512 / 3 9435513",
                          fax: "08 3 9435514",
                      },
                      
                  },
              ],
          },
      },
      {
          company: 3,
          info: {
              logo: "assets/images/logos/PacificLogos.jpg",
              title: "PACIFIC LOGISTICS NETWORK JOINT STOCK COMPANY",
              address: "R506, F5th V.E.T Building, 98 Hoang Quoc Viet Street, Cau Giay Dist, Hanoi, Vietnam",
              website: "www.dnlogis.vn",
              head_office: {
                  address: "Rm 506, 05 Fl., V.E.T Building, 98 Hoang Quoc Viet Str., Cau Giay Dist., Hanoi city, Vietnam",
                  tel: "(84-024) 3 212 1246/ 47",
              },
              hochiminh: {
                  address: "Rm 601, 31 Hoang Dieu Street, Dist 4, Hochiminh City, Vietnam",
                  tel: "(84-028) 3 943 5512",
              },
              haiphong: {
                  address: "Rm 208, Holiday Mansion Hotel, 441 Da Nang Str., Hai An Dist., Hai Phong city, Vietnam",
                  tel: "(84-0225) 3 555 344"
              },
              print: [
                  {
                      place: 0,
                      name: "HAIPHONG",
                      sales: { 
                          name: "Mrs Cung Lan",
                          ext: "23",
                          mob: "0986929882",
                          email: "sales@sel.vn"
                      },
                      
                  },
                  {
                      place: 1,
                      name: "HOCHIMINH",
                      address : "Rm 601, 31 Hoang Dieu Str, Dist 4, Hochiminh City, Vietnam",
                      note: "For information about shipping, booking, telex release, cargo tracking, … do not hesitate contact us.",
                      sales: { 
                          name: "Mr Khanh",
                          ext: "23",
                          tel: "08 3 9435512 / 3 9435513",
                          fax: "08 3 9435514",
                      },
                  },
              ],
          },
      },
  ];
  api = 'http://localhost:8080';
  api1 = 'https://api.ishipto.vn';
  api2 = 'https://ishipto-node10.appspot.com';
  public apiUrl = null

  // store the URL so we can redirect after logging in
  public redirectUrl: string;
  public signIn = false;
  //auth2: any;
  //token = null;
  //number = 1;
  //sender = null;
  //fileName = '';
  public attachFile = [];
  public data;   
  public web;
  public error;
  onLoginChanged: BehaviorSubject<any>;

  constructor(
    private location: Location,
    private _httpClient: HttpClient,
    private datePipe: DatePipe
  ){
    this.apiUrl = this.api2
    this.version = '5.96'
    this.onLoginChanged = new BehaviorSubject(false);
  }

  
  login(data): void{
    this.isLoggedIn = true;
    this.accessToken = data.accessToken;
    this.user = data.id;
    this.userType = data.type;
    this.name = data.name;
    this.email = data.email;
    this.permission = data.permission;
    this.company = data.company;
    this.branch = data.branch,
    this.department = data.department,
    this.companyMail = data.company_mail;
    this.web = data.web;
  }
  public logout(): void {
    this.isLoggedIn = false;
    this.accessToken = null;
    this.user = null;
    this.userType = null;
    this.name = null;
    this.email = null;
    this.permission = null;
    this.company = null;
    this.web = null;
    if(this.signIn){
      let that = this;
      let auth2 = gapi.auth2.getAuthInstance();
      auth2.signOut().then(function () {
        console.log('User signed out.');
        that.signIn = false;
      });
    }
  }
  public check(user): void{
    this.checkValue = btoa(user.email + ':' + user.password);
  }

  getPermission(modul, type){
    let permission = this.permission.find(item => item.modul == modul);
    if(typeof permission == 'undefined'){
      return 0;
    }
    return permission[type];
  }
  getAction(modul){
      let permission = this.permission.find(item => item.modul == modul);
      let action
      if(permission){
        action = {
          id: permission.id,
          create: permission.create_data == 1 ? true : false,
          read: permission.read_data == 1 ? true : false,
          edit: permission.update_data == 1 ? true : false,
          delete: permission.delete_data == 1 ? true : false,
          data: permission.data == 'public' ? true : false,
          web: this.web
        }
      } else {
        action = {
          create: false,
          read: false,
          edit: false,
          delete: false,
          data: false,
          web: this.web
        }
      }
      return action;
  }
  doLogout(){
    return new Promise((resolve, reject) => {
      if(this.isLoggedIn){
        this.logout();
        resolve();
      }
      else{
        reject();
      }
    });
  }
  goBack(){
    this.location.back();
    this.back = true;
  }
  
  checkSelShipmentStatus(shipment){
      if(shipment.status == 2) {
          return true
      } else {
          return false
      }
  }  
}