// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr       : false,
    firebase: {
	    apiKey: "AIzaSyBmKqDlZLOFsJWenlxsQvwQsTdmM1B2jxw",
	    authDomain: "admin-ishipto-2018.firebaseapp.com",
	    databaseURL: "https://admin-ishipto-2018.firebaseio.com",
	    projectId: "admin-ishipto-2018",
	    storageBucket: "admin-ishipto-2018.appspot.com",
	    messagingSenderId: "375606479907",
  		appId: "1:375606479907:web:a8ed5a74dcf664cb"
	  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
